import React from 'react';
import Header from '../components/Header';
// import Footer from '../components/Footer';
import { Pexel1, Others, Pexel3, Pexel4, Pexel5, Banner, Others2 } from '../assest/image';
import { Button, Form, Input, Carousel } from 'antd';
import Cards from '../components/Cards';
import { YoutubeFilled, LinkedinFilled, InstagramFilled, FacebookFilled, TwitterSquareFilled } from "@ant-design/icons"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const FormItem = Form.Item;


const Home = () => {
  const [form] = Form.useForm();
  return (
    <>
      <Header />

      <div className="bg-black flex flex-col">
        <div style={{
          backgroundImage: `url(${Banner})`, 
          height: "90vh",
          backgroundColor: "rgb(0, 0, 0, 0.7)",
          backgroundBlendMode: "multiply"
        }} 
        className="bg-cover bg-no-repeat bg-center flex flex-col items-center justify-center gap-6"
        >

          <h2 className="font-bold text-center text-white font-dm" style={{fontSize: "50px"}}>Secure Your Digital World Now</h2>
          <p className="text-white text-xl w-full md:w-1/2 text-center font-nun">Guard against cyber threats with advanced protection solutions tailored for your peace of mind.</p>

          <div>
            <Button type="primary" className="h-14 flex flex-row items-center justify-center font-bold w-40 font-dm">
              Learn More
            </Button>
          </div>

        </div>

        <div className="h-fit py-16 md:py-0 md:h-screen flex flex-col items-center justify-center text-center text-local font-medium ">
          <p className="w-full px-2 md:px-0 md:w-3/4 text-2xl md:text-4xl italic font-nun">Introducing SmartSafe, a cybersecurity company based in Greater London, England. With a focus on protecting your digital assets, SmartSafe offers cutting-edge solutions to safeguard your information from cyber threats. Trust SmartSafe to keep your data secure and your business protected in today's ever-evolving digital landscape.</p>
        </div>
        
        <div className="h-fit pb-16 md:pb-0 md:h-screen flex flex-col items-center justify-center px-8 gap-10" id="products">
          <h2 className="text-local text-medium text-center font-bold font-dm">What we do</h2>

          <div className="flex flex-col md:flex-row items-center gap-4">
            <Cards 
              picture={Pexel4}
              name="Internet of Things"
              href="#iot"
              description="Suite of services built around the growing prospects of Internet-of-Things"
            />
            <Cards 
              picture={Pexel5}
              name="Cloud Services"
              description="We deliver your cloud requirements with peace of mind."
              href="#cs"
            />
            <Cards 
              picture={Pexel3}
              name="Cybersecurity"
              description="Experienced team with in-depth knowledge in cyber security"
              href="#gs"
            />
            <Cards 
              picture={Pexel1}
              name="Governance Risk & Compliance"
              description="Our GRC team are versatile with Governance Risk & Compliance."
              href="#grc"
            />
          </div>
        </div>

        <div className=" h-fit pb-16 md:pb-0 md:h-screen bg-black flex flex-col-reverse gap-8 md:gap-0 md:flex-row items-center mb-24 md:mb-0">
          <div className="flex flex-col items-center justify-center md:justify-start gap-8 w-fill px-2 md:px-12 md:w-1/2 ">
            <p className="text-center md:text-left text-medium text-white font-bold font-dm">Protect Your Business with SmartSafe Cybersecurity Solutions</p>

            <p className=" text-white font-normal text-center md:text-left font-nun">Safeguard your valuable data and secure your business with SmartSafe, a leading cybersecurity company based in Greater London, England. Our expert team specializes in providing comprehensive cybersecurity solutions tailored to your specific needs. Trust SmartSafe to protect your business from cyber threats and ensure peace of mind.</p>

          <div className="flex flex-row items-center justify-center md:justify-start w-full">
            <Button type="primary" className="h-14 flex flex-row items-center justify-center font-bold w-40 font-dm">
              Learn More
            </Button>
          </div>
            
          </div>

          <div
            style={{
              backgroundImage: `url(${Others2})`, 
              backgroundColor: "rgb(0, 0, 0, 0.3)",
              backgroundBlendMode: "multiply",
            }}
            className="w-full md:w-1/2 h-96 md:h-full bg-cover bg-center"
          >

          </div>
        </div>

        <Carousel className="mb-20" autoplay>
          <div>
            <div 
            className="flex flex-col gap-10 px-8, bg-cover bg-no-repeat bg-top py-20" 
            style={{
              backgroundImage: `url(${Banner})`, 
              // height: "90vh",
              backgroundColor: "rgb(0, 0, 0, 0.7)",
              backgroundBlendMode: "multiply"
            }} id="iot">
              <h2 className="text-3xl text-local font-bold text-center font-dm">Internet of Things</h2>

              <div className="text-white flex flex-col gap-8 items-center">
                <p className="text-center text-xl font-nun md:w-3/4">We understand that “Anything that can be connected, will be connected”. Our team is positioned to harness and deliver for your organisation these new opportunities through Internet of Things. SmartSafe IT experts have clear understanding of the technologies from a dual perspective. We specialize in the art of connecting things, people and technology to improve productivity.</p>

                <ul className="text-local italic font-dm px-4 md:px-0 md:w-2/3  flex flex-col gap-2">
                  <li><strong>IoT Automation & Consulting: </strong>We help clients efficiently capitalize on IoT technology linking technology, solutions, vendors and customers through a holistic business model.</li>
                  <li><strong>Digital Business Strategy: </strong>We use digital technologies to business models to form new differentiating business capabilities.</li>
                  <li><strong>Data Driven Strategy: </strong>Strategic decisions are made using data analysis and interpretation.</li>
                </ul>
              </div>
              
            </div>
          </div>
          
          <div>
            <div 
            className="flex flex-col gap-10 px-8, bg-cover bg-no-repeat bg-top py-20" 
            style={{
              backgroundImage: `url(${Banner})`, 
              // height: "90vh",
              backgroundColor: "rgb(0, 0, 0, 0.7)",
              backgroundBlendMode: "multiply"
            }} id="cs">
              <h2 className="text-3xl text-local font-bold text-center font-dm">Cloud Services</h2>

              <div className="text-white flex flex-col gap-8 items-center">
                <p className="text-center text-xl font-nun md:w-3/4">We will transform the way your organisation consumes IT services by offering increased scalability, resilience and security with our cloud solutions. Your organisation is unique and your strategy to utilise cloud services will need to be tailored to consider the supportability of your applications and the governance around your data. With so many considerations our experienced consultants can help you to make informed decisions considering the scalability and resilience requirements for your transition to your chosen cloud solution.</p>

                <ul className="text-local italic font-dm px-4 md:px-0 md:w-2/3 text-center flex flex-col gap-4">
                  <li><strong>CSA/Cloud Consulting: </strong>Cloud Security Assessment is our consulting service for AWS, Azure & Google Cloud</li>
                  <li><strong>Migration & Transformation: </strong>Cloud Migration, Modernisation and Transformation understands that cloud is key to how your business responds to the new challenges in business.</li>
                  <li><strong>Platform Solution: </strong>Our Platform Solutions and Container Service provide the foundation for a new service centric model that facilitates cloud-based business solutions.</li>
                  <li><strong>Network & Hosting: </strong>We design and manage intelligent IT networks and hosting solutions that deliver huge value to organisations.</li>
                </ul>
              </div>
              
            </div>
          </div>

          <div>
            <div 
            className="flex flex-col gap-10 px-8, bg-cover bg-no-repeat bg-top py-20" 
            style={{
              backgroundImage: `url(${Banner})`, 
              // height: "90vh",
              backgroundColor: "rgb(0, 0, 0, 0.7)",
              backgroundBlendMode: "multiply"
            }} id="gs">
              <h2 className="text-3xl text-local font-bold text-center font-dm">GRC Services</h2>

              <div className="text-white flex flex-col gap-8 items-center">
                <p className="text-center text-xl font-nun md:w-3/4">We help simplify your governance strategy. We help you manage and govern all your business content as increasing data is cumbersome along with compliance of complex regulations to avoid fine and lawsuits. Our experts would help centralise your GRC into a single platform that would connect and power all the elements of your GRC program unlocking new insights, streamlining manual processes. We transform your GRC program from a reactive, tick box exercise into a powerful tool.</p>

                <ul className="text-local italic font-dm px-4 md:px-0 md:w-2/3 text-center flex flex-col gap-4">
                  <li><strong>IT Audit Management: </strong>We help organisations understand their key technology risks.</li>
                  <li><strong>Compliance Management: </strong>We assess your compliance position (GDPR, NDPR, PCI DSS, ISO 27001/2 & ISO 9001) and developing a remediation roadmap.</li>
                  <li><strong>Vendor Management: </strong>Our vendor management solution makes it easy to build and maintain a comprehensive vendor directory and manage your vendor lifecycle.</li>
                  <li><strong>Enterprise Risk Mgt: </strong>We supports and provide delivery strategy in enterprise risk management for clients, managing costs, attracting investment, making better decisions and responding to risk.</li>
                </ul>
              </div>
              
            </div>
          </div>

          <div>
            <div 
            className="flex flex-col gap-10 px-8, bg-cover bg-no-repeat bg-top py-20" 
            style={{
              backgroundImage: `url(${Banner})`, 
              // height: "90vh",
              backgroundColor: "rgb(0, 0, 0, 0.7)",
              backgroundBlendMode: "multiply"
            }} id="grc">
              <h2 className="text-3xl text-local font-bold text-center font-dm">Cyber Security</h2>

              <div className="text-white flex flex-col gap-8 items-center">
                <p className="text-center text-xl font-nun md:w-3/4">We create framework that protects your network, data and people. We deliver technology, processes and practices to protect your IT infrastructure. Trusted by clients in various sectors where we provide consulting security services. We believe building security into systems at the earliest concept and design phases. We offer end-to-end cyber security service to protect networks, staff, & customer data.</p>

                <ul className="text-white italic font-dm px-4 md:px-0 md:w-2/3 flex flex-col gap-2">
                  <li><strong>Threat & Vulnerabilities: </strong>Comprehensive threat and vulnerability assessments.</li>
                  <li><strong>SIEM Consulting: </strong>SIEM consulting (Integration, Implementation & Training: Security Information and Event Management (SIEM).</li>
                  <li><strong>Network Security: </strong>Network Security & Segmentation is our approach to the ever-growing size and heterogeneous nature of networks and IT.</li>
                  <li><strong>Incident Response: </strong>Cyber security incidents have become inevitable; the result of our increasingly interconnected and technology enabled world.</li>

                  
                </ul>
              </div>
              
            </div>
          </div>
          
        </Carousel>

        <div className="h-fit pb-16 md:pb-0 md:h-screen flex flex-col items-center justify-center px-8 gap-10" id="about">
          <h2 className="text-local text-medium text-center font-bold font-dm">About us</h2>

          <p className="w-full md:w-3/4 text-xl italic text-white text-center font-nun">Welcome to SmartSafe, a leading cybersecurity company based in Greater London, England. With a focus on protecting businesses from digital threats, we are dedicated to providing top-notch security solutions to our clients. Our team of experts is committed to staying ahead of the curve in the ever-evolving world of cybersecurity, ensuring that our clients can operate with peace of mind in today's digital landscape. Our overarching aim is to safeguard your information so that you can focus on taking your business where you want it to go. Everything we do is guided by our solid principles. Our goal is to protect your information,so that you can focus o nwhat you do best take care of your business.</p>
          
        </div>

        <div className="h-fit pb-10 md:pb-0 md:h-screen flex flex-col items-center justify-center px-8 gap-10">
          <h2 className="text-local text-medium text-center font-bold font-dm">Get in Touch</h2>

          <div className="flex flex-col-reverse md:flex-row items-center gap-4 w-full">

            <div className="flex flex-col items-center justify-center w-full">
              <p className="text-white italic text-sm mb-8 text-medium text-center font-nun">Follow us on our social media</p>

              <div className="flex flex-row items-center justify-center gap-6">

              <a href="https://web.facebook.com/profile.php?id=100069288638366" target="_blank" rel="noreferrer"><FacebookFilled className="text-local text-3xl"  /></a>
                
                
                <a href="https://www.instagram.com/smartsafeuk" target="_blank" rel="noreferrer">
                  <InstagramFilled className="text-local text-3xl" />
                </a>

                <a href="https://www.linkedin.com/company/smartsafeuk/mycompany/" target="_blank" rel="noreferrer"><LinkedinFilled className="text-local text-3xl" /></a>
                
                <YoutubeFilled className="text-local text-3xl" />

                <a href="https://twitter.com/Smartsafe_Uk" target="_blank" rel="noreferrer"> <TwitterSquareFilled className="text-local text-3xl" /></a>
               
              </div>
            </div >
              

            <div className="w-full pr-8">

              <p className="text-white italic text-sm mb-8 text-medium text-center">Send us a message and we'll get back to you as soon as we can.</p>

              <Form form={form} layout="vertical" className="w-full">
                  <FormItem name="name" rules={[{required: true, message: "Please Enter Yout full Name"}]}>
                    <Input 
                      placeholder='Full name'
                      size="large"
                    />
                  </FormItem>
                <div className="flex flex-row items-center gap-4">
                  <FormItem name="email" rules={[{required: true, message: "Enter a valid email", type: "email"}]}>
                    <Input 
                      placeholder='example@gmail.com'
                      className="w-full"
                      size="large"
                    />
                  </FormItem>

                  <FormItem name="phone_number" rules={[{required: true, message: "Please Enter Your phone number"}]}>
                    <PhoneInput 
                      placeholder='Phone number'
                      country="us"
                      inputStyle={{width: "100%", borderRadius: 8}}
                      dropdownStyle={{borderRadius: 8}}
                    />
                  </FormItem>
                </div>

                <FormItem name="message" rules={[{required: true, message: "Please Enter Your message"}]}>
                  <Input.TextArea
                    rows={5}
                    placeholder='Message'
                    maxLength={200}
                  />
                </FormItem>
                
                <FormItem>
                  <Button type="primary" className="h-14 flex flex-row items-center justify-center font-bold w-40">
                    Submit
                  </Button>
                </FormItem>
                
              </Form>
            </div>
          </div>
        </div>
      </div>
      
      {/* <Footer/> */}
    </>
    
  )
}

export default Home