const theme = {
    token: {
      fontSize: 16,
      colorPrimary: '#4F7942',
      colorPrimaryBg: "#3c323c",
    },
    components: {
        Button: {
          colorPrimary: '#4F7942',
          colorPrimaryBg: "#3c323c", 
        }
    }
  };
  
  export default theme;