import './App.css';
import 'tailwindcss/tailwind.css';
import Home from './pages/Home';
import { ConfigProvider } from 'antd';
import theme from './theme/themeConfig';

function App() {
  return (
    <ConfigProvider theme={theme}>
      <Home />
    </ConfigProvider>
  );
}

export default App;
