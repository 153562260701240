export { default as LogoPic } from "./smartsafe.jpg";
export { default as Pexel1 } from "./pexels1.jpg";
export { default as Pexel2 } from "./pexels2.jpg";
export { default as Pexel3 } from "./pexels3.jpg";
export { default as Pexel4 } from "./pexels4.jpg";
export { default as Pexel5 } from "./pexels5.jpg";
export { default as Pexel6 } from "./pexels6.jpg";
export { default as Pexel7 } from "./pexels7.jpg";
export { default as Pexel8 } from "./pexels8.jpg";
export { default as Pexel9 } from "./pexels9.jpg";
export { default as Pexel10 } from "./pexels10.jpg";
export { default as Pexel11 } from "./pexels11.jpg";
export { default as Pexel12 } from "./pexel12.jpg";
export { default as Pexel13 } from "./pexel13.jpg";
export { default as Banner } from "./banner.svg";
export { default as Others } from "./others.svg";
export { default as Others2 } from "./others2.png";
export { default as New } from "./New.svg";