import React, { useEffect, useState } from 'react';
import { Logo2 } from '../assest/logo';
import { New } from '../assest/image';
import { Button, Image } from "antd";
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import $ from "jquery"

const Header = () => {
  const [ showMenu, setShowMenu ] = useState(false);

  const handleShowMenu = () => {
    $("#menu").slideToggle();
    setShowMenu(!showMenu);
  }

  useEffect(() => {
    $("#menu").hide();
  }, [])
  return (
    <div className="fixed bg-black flex flex-row py-4 md:py-0 items-start md:items-center justify-between h-fit md:h-24 w-full pr-8 md:px-10 z-10">
      <div className="h-fit flex flex-col">
        <div>
          <Image 
            src={New} 
            alt="logo image" 
            preview={false}
            width={250}
            height={60}
          />
        </div>

        {<div className="flex md:hidden flex-col gap-6 items-start pl-5 py-8 justify-between w-full h-fit" id="menu">
          <AnchorLink href="#products"><p className="text-white font-bold">Product</p></AnchorLink>
          <AnchorLink href="#about"><p className="text-white font-bold" >About us</p></AnchorLink>
          
          
          <Button type="primary" className="font-bold h-14 flex flex-row items-center justify-center">
            Contact Us
          </Button>
        </div>}
      </div>

      <div className="hidden md:flex flex-row items-center justify-between w-2/4 ">
        <AnchorLink href="#products"><p className="text-white font-bold">Product</p></AnchorLink>
        <AnchorLink href="#about"><p className="text-white font-bold" >About us</p></AnchorLink>
        
        
        <Button type="primary" className="font-bold h-14 flex flex-row items-center justify-center">
          Contact Us
        </Button>
      </div>

      <div className="pt-2 md:hidden">
        {!showMenu && <MenuOutlined className="text-local text-3xl font-bold cursor-pointer" onClick={handleShowMenu} />}
        {showMenu && <CloseOutlined className="text-local text-3xl font-bold cursor-pointer" onClick={handleShowMenu}/>}
      </div>

    </div>
  )
}

export default Header