import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Others } from '../assest/image';

const Cards = ({ name, description, href }) => {
  return (
    <AnchorLink className="w-full" href={href}>
      <div 
        style={{
            height: 300,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            backgroundImage: `url(${Others})`,
            backgroundBlendMode: "multiply"
        }} 
        className={`flex flex-col items-center justify-center gap-8 bg-back transition-all ease duration-1000 bg-cover bg-center cursor-pointer  w-full hover:bg-blend-darken hover:bg-black`}
    >
        <h2 className="text-3xl text-local font-bold text-center font-dm">{name}</h2>

        <p className="text-white text-lg text-center w-4/5 font-nun font-medium">{description}</p>
    </div>  
    </AnchorLink>
  )
}

export default Cards